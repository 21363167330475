<template>
  <div class="machine-settings-details">
    <b-container>
      <b-row>
        <b-col cols="9" offset="1">

          <vs-loader
            full
            style="top: 0"
            v-if="isLoading"
          />

          <base-resource-form
            v-model="fields"
            :feedback="feedback"
            :type="type"
          />

          <form-action-buttons
            @submit="update"
            @cancel="$router.push({ name: 'base.machines.details' })"
          />

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';
import FormActionButtons from '@/components/ui/FormActionButtons.vue';
import {
  computed, inject, ref, watch,
} from 'vue';
import baseResourceFormValidations from '@/services/forms/base/resource';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { UpdateResourceError, UpdateResourceSuccess } from '@/libs/toasts/feedback/module/base';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import BaseResourceForm from '../../resource/forms/BaseResourceForm.vue';

export default {
  props: {
    type: {
      type: String,
      default: 'vehicle',
    },
  },
  setup(props, { emit }) {
    const machine = inject('machine');
    const validationRules = computed(() => {
      if (!props.type) return baseResourceFormValidations.default;
      return baseResourceFormValidations[props.type] ?? baseResourceFormValidations.default;
    });
    const isLoading = ref(false);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      nonEmptyFields,
      assignServerSideErrors,
      assignFields,
    } = useValidatedForm({
      ...validationRules.value,
    });

    watch(() => machine.value, () => {
      assignFields({
        ...machine.value,
        base_resource_activity: {
          ...machine.value.last_activity ?? {},
          base_resource_collaboration: {
            ...machine.value.last_activity?.collaboration ?? {},
          },
        },
      });
    });

    const updateResource = async () => {
      if (!(await areAllFieldsValid())) return;
      isLoading.value = true;

      const sendData = {
        ...nonEmptyFields.value,
        base_resource_activity: {
          ...fields.value.base_resource_activity,
          base_resource_collaboration: {
            ...fields.value.base_resource_activity?.base_resource_collaboration ?? {},
          },
        },
      };

      if (!sendData.base_resource_activity?.base_resource_collaboration?.crm_company_id) {
        delete sendData.base_resource_activity?.base_resource_collaboration;
      }

      useBaseApi()
        .updateResource(machine.value.id, {
          ...sendData,
          base_resource_type_id: props.type,
        })
        .then(({ data }) => {
          showToast(UpdateResourceSuccess, { name: data.data?.name });
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(UpdateResourceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      machine,
      update: updateResource,
      fields,
      feedback,
      isLoading,
    };
  },
  components: {
    FormActionButtons,
    BContainer,
    BRow,
    BCol,
    BaseResourceForm,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.machine-settings-details
  padding: 50px
</style>
