<template>
  <div class="machine-settings">
    <b-card>
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="SlidersIcon" />
            Ustawienia maszyny
          </template>

          <vs-loader
            full
            style="top: 0"
            v-if="isLoading"
          />

          <machine-settings-details />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { provide, ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import { useRouter } from '@/@core/utils/utils';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import MachineSettingsDetails from './MachineSettingsDetails.vue';

export default {
  name: 'MachineSettings',
  setup() {
    const { route } = useRouter();
    const machineId = route.value.params.id;
    const machine = ref({ id: machineId });
    const isLoading = ref(true);

    useBaseApi()
      .fetchMachine(machineId)
      .then(({ data }) => {
        const collab = data.data?.last_activity?.collaboration;

        machine.value = {
          ...data.data,
          id: data.data.id,
          base_resource_daily_cost: data.data.actual_daily_cost,
          base_group_id: data.data.last_activity?.base_group_id ?? null,
          base_resource_id: data.data.id,
          base_technology_id: data.data?.technologies?.map((t) => t.id) ?? [],
          crm_company_id: collab?.crm_company_id,
          start_date: collab?.start_date,
          end_date: collab?.end_date,
        };
      })
      .finally(() => {
        isLoading.value = false;
      });

    provide('machine', machine);

    return { isLoading };
  },
  components: {
    BCard,
    BTabs,
    BTab,
    MachineSettingsDetails,
    VsLoader,
  },
};
</script>

<style scoped>

</style>
