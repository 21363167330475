var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "machine-settings-details"
  }, [_c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9",
      "offset": "1"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _c('base-resource-form', {
    attrs: {
      "feedback": _vm.feedback,
      "type": _vm.type
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  }), _c('form-action-buttons', {
    on: {
      "submit": _vm.update,
      "cancel": function ($event) {
        return _vm.$router.push({
          name: 'base.machines.details'
        });
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }